/* Page Layout */

.home-board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.top-level,
.bottom-level {
    display: flex;
    align-items: stretch;
    height: 100%;
}

.top-level {
    margin: .5rem .5rem 0;

}

.bottom-level {
    margin: 0 .5rem .5rem;
}

/* Indicator Containers */

.indicator {
    background-color: var(--light-blue);
    border-radius: 30px;
    flex: 1 1 50%;
    margin: .4rem;
    padding: 0 20px 20px 20px;
}

.section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem;
    margin-bottom: 20px;
}

.indicator-inner-box {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    padding: 5px;
    flex: 1 1 clamp(10rem, 20vw, 25rem);
    min-height: 6rem;
}

.indicator-header {
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* font-size: 30px; */
    font-size: clamp(2rem, 2vw, 5rem);
    font-weight: 200;
}

.indicator-icon {
    fill: var(--primary-color);
    padding: 5px;
    height: clamp(3rem, 3vw, 6rem);
    width: clamp(3rem, 3vw, 6rem);
}

.indicator-main {
    display: flex;
    min-height: calc(100% - clamp(2rem, 2vw, 5rem));
}

.stats-value {
    font-size: clamp(1.5rem, 2vw, 5rem);
    font-weight: bold;
}

.stats-value span {
    margin-left: 10px;
    font-size: clamp(1.25rem, 1vw, 2rem);
}

.stats-caption {
    font-size: clamp(1rem, 1vw, 5rem);
    font-weight: 200;
    color: var(--primary-color);
}

@media screen and (max-width: 1150px) {

    .top-level, .bottom-level {
        flex-wrap: wrap;
    }

    .indicator {
        min-height: 20rem;
    }

    .indicator-main {
        justify-content: center;
    }

    .section {
        width: 100%
    } 

}

@media screen and (min-width: 1300px) {
    .section {
        gap: 1rem;
    }
}


