footer {
    display: flex;
    padding: .75rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: var(--white);
    position: relative;
    bottom: 0;
    font-family: var(--secondary-font);
}

.footer-contact {
    gap: .5rem;
    align-items: center;
    display: flex;
    flex-wrap: wrap; 
}

.footer-logo {
    height: 1.5rem;
    width: auto;
    padding: .2rem 1rem;
    background-color: var(--white);
    border-radius: 10px;
}

@media only screen and (max-width: 856px) {
  
    .footer-contact {
       
         flex-wrap: wrap; 
       
    }

  }