/* Side panel  */

.side-panel {
  /* max-width: 160px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--light-blue);
  transition: max-width 0.75s ease; /* 0.5 second transition effect to slide in the sidepanel */
/* Change font here */
  /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
}

/* Side panel formatting */

.side-panel-header {
  /* top sides bottom */
  padding: 1rem 1.5rem;
}

.side-panel-header + hr {
 margin: 0rem 0rem .5rem;
}

.side-panel-item + hr {
 margin: .5rem 0rem 0rem;
}

.side-panel-item {
  display: flex;
  justify-content: center;
  padding: .35rem 0;
}

/* Sidebar items buttons styles */

.home-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--white);
}

.home-button-size {
  width: 3.75rem;
  height: 3.75rem;
}

.info-button-size {
  margin: 12px 0px;
  width: 3rem;
  height: 3rem;
}

.home-button:hover {
  text-decoration: none;
  color: var(--white);
}

.home-button-text {
  margin: 0;
  font-size: 1.25rem;
}

/* .home-button-size {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(180deg) brightness(103%) contrast(102%);
} */

.button-styles-color {
  background-color: var(--green);
}

.button-styles, .home-button-size {
  padding: 10px;
  border-radius: 10px;
}

.button-styles::before {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-.15rem);
  content: attr(data-tooltip);
  text-transform: capitalize;
  position: absolute;
  left: 7.5rem;
  background-color: var(--blue); 
  color: var(--white);
  font-size: 1.25rem;
  padding: 10px;
  height: 3rem;
  width: 7rem;
  border-radius: 10px;
  transition: all 200ms ease-in;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  border: var(--primary-color) solid 1px;
}

.info-button-size::before {
  transform: translateY(0rem);
}

.button-styles:hover:before {
  opacity: 1;
  visibility: visible;
  left: 6.5rem;
}
.button-styles:hover {
  background-color: var(--blue);
  transition: 0.4s ease;
}


/* SVG icons */

.icon {
  /* background-color: var(--blue); */
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(161deg) brightness(106%) contrast(101%);
}

.icon
{
  max-height: 2.5rem;
}

/* Bottom logo */

.logo-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0 1rem;
}

#techimpact-logo-small {
  width: 3.75rem;
  height: 3.75rem;
  background-color: var(--white);
  border-radius: 10px;
  padding: 10px;
}

@media screen and (max-width: 500px) {

  .side-panel {
      display: none;
  }

}