
.flex {
    display: flex;
}

.notes-container, .chart-container {
    display: flex;
    flex-direction: column;
}

.chart-container {
    width: 65%;
    flex: 1 0 auto;
}

.notes-container {
    width: 35%;
}

.header {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 1.5rem 1.5rem 0rem;
    color: var(--primary-color);
}

.gap-class {
    margin: 1.5rem;
}

.value-box, .notes {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 2rem 1rem;
    border-radius: 10px;
    /* width: 40%; */    
}

.value-box {
    background-color: var(--blue);
    color: var(--white);
    text-align: center;
    min-height: 10rem;
    min-width: 40%;
}

.notes {
    background-color: var(--light-blue);
    color: var(--primary-color);
}

.big-font {
    font-size: 2.5rem;
}

hr {
    margin: 1rem;
    border: var(--primary-color) solid 1px;
    opacity: 15%;
  }

@media only screen and (max-width: 1300px) {
    .chart-container {
        width: 100%;
    }

    .notes-container {
        width: 100%;
        justify-content: center;
        flex-direction: row;
    }
    
    .flex {
      flex-direction: column;
    }
  }


@media only screen and (max-width: 1000px) {

    .notes-container {
        flex-direction: column;
    }
  }