@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600&display=swap');

/* :root {
  --primary-color: #1E3B60;
  --green: #49A25C;
  --blue: #208fce;
  --white: #ffffff;
  --light-blue: #ECF4FA;
  --yellow: #F7CB55; 
  --orange: #E6893C;
  --gray: #bebebe;
  --primary-font: 'Oswald', Helvetica, sans-serif;
  --secondary-font: 'Roboto Mono', mono;
} */

:root {
  --primary-color: #123c63;
  --green: #00a454;
  --blue: #0c8ccd;
  --white: #ffffff;
  --light-blue: #ECF4FA;
  --yellow: #ffc934; 
  --orange: #f5831f;
  --gray: #d9d9d9; 
  --primary-font: 'Oswald', Helvetica, sans-serif;
  --secondary-font: 'Roboto Mono', mono;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  font-family: var(--primary-font);
  color: var(--primary-color);
  min-width: 280px;
  letter-spacing: .02em;
}

/* body::after {
  content: "";
  display: block;
  height: 60px;
} */

/* Temporary solution: 
Height = full vh minus height of navbar, footer 
Width = 100% or 100vw?
*/
.content-wrap {
  /* height: calc(100vh - 203px); */
  /* width: 100vw; */
  width: calc(100vw - 96px);
}

#page-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#content-sidebar-wrap {
  flex: 1;
}

@keyframes fadein { from { opacity: 0; } to { opacity: 1; } }

@media screen and (max-width: 500px) {

  .content-wrap {
      width: 100vw;
  }

}






